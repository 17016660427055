import React from 'react';

export const Check = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 516.44 502.58"
        x="0px"
        y="0px"
        fill="#639a3d"
    >
        <g>
            <path
                className="fil0"
                d="M496.77 42.98c-70.91,48.85 -94.77,48.6 -152.91,123.76 -64.67,83.61 -98.29,169.08 -141.2,263.38 -16.57,36.41 -46.77,106.17 -88.38,53.41 -24.5,-31.07 -38.19,-54.76 -66.98,-86.21 -5.83,-6.37 -77.32,-73.21 -32.74,-71.86 57.26,1.74 113.96,102.76 143.77,70.44 12.01,-13.01 48.84,-102.49 62.07,-127.54 48.02,-90.91 149.61,-237.68 253.6,-265.61 39.65,-10.65 60.1,11.09 22.77,40.23z"
            />
        </g>
    </svg>
);

export const Uncheck = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#e02231"
        x="0"
        y="0"
        viewBox="0 0 100 100"
    >
        <path d="M87.301 6.568a5.44 5.44 0 00-2.381-1.383A4.987 4.987 0 0083.587 5c-.967 0-1.875.289-2.815.727-1.573.732-3.155 1.387-4.643 2.286-1.848 1.107-3.535 2.618-5.17 4.011-2.918 2.481-5.57 5.279-8.269 7.983-2.922 2.927-5.693 6.002-8.425 9.1-1.829 2.072-3.673 4.144-5.427 6.278-.808.984-1.623 1.963-2.438 2.942-2.221-2.331-4.445-4.658-6.669-6.987-2.129-2.234-4.344-4.382-6.52-6.567-1.264-1.273-2.528-2.542-3.792-3.816-.984-.988-2.067-1.887-3.108-2.818s-2.072-1.863-3.132-2.766c-.822-.698-1.644-1.392-2.466-2.091-1.107-.941-2.262-1.73-3.521-2.471a3.97 3.97 0 00-2.036-.552 4.104 4.104 0 00-2.901 1.189c-1.501 1.501-1.597 4.056-.144 5.618 2.871 3.927 6.04 7.633 9.149 11.376 3.103 3.735 6.363 7.352 9.542 11.02 2.519 2.909 4.954 5.889 7.401 8.857-.565.713-1.137 1.421-1.692 2.141-3.773 4.904-7.541 9.808-11.191 14.808a1014.071 1014.071 0 00-5.132 7.119c-1.416 1.977-2.851 3.944-4.03 6.068-2.338 4.201-4.448 10.669-.071 14.413a7.323 7.323 0 003.222 1.877 7.37 7.37 0 005.627-.737c2.218-1.297 3.641-3.801 3.635-6.35.195-.594.401-1.181.619-1.771 1.977-4.306 4.427-8.448 6.918-12.47l3.811-6.149c.946-1.521 2.034-2.965 3.046-4.438a437.011 437.011 0 013.162-4.558c.584.761 1.167 1.523 1.755 2.28l-1.112-1.435c3.208 4.519 6.444 9.014 9.419 13.691a642.338 642.338 0 014.9 7.808c1.796 2.913 3.588 5.888 6.083 8.273 2.837 2.718 7.128 3.379 10.573 1.364a8.814 8.814 0 004.011-5.213c.637-2.319.247-4.638-.879-6.724-1.107-2.053-2.599-3.825-4.025-5.655-.965-1.245-2.02-2.433-3.037-3.635-1.977-2.329-3.963-4.652-5.926-6.99-4.306-5.119-8.687-10.149-13.262-15.025.96-1.248 1.921-2.496 2.879-3.746 3.793-4.612 7.616-9.199 11.648-13.604 3.42-3.737 6.923-7.518 10.798-10.81 1.065.41 2.25.474 3.358.17 1.421-.39 2.381-1.278 3.198-2.457 1.003-1.445 1.558-3.113 2.176-4.747.666-1.756-.123-3.985-1.383-5.249z" />
    </svg>
);
