import React from 'react'
import styled from 'styled-components'
import vars from '../lib/styled'

export const HeadingLevel2Large = styled.h2`
  font-size: 3.1em;
  margin-bottom: 1rem;
  margin-top: 0.25rem;

  @media (max-width: ${vars.breakpoint.tablet}) {
    font-size: 2.1em;
    line-height: 1.1;
  }
`

export const HeadingLevel2 = styled.h2`
  font-size: 2.6em;
  margin-top: 1rem;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`

export const SubHeading = styled.p`
  color: ${vars.primary};
  font-size: 1.051em;
  margin-bottom: 0.5rem;
`

export const HeadingLevel3WithIcon = styled.h3`
  font-size: 1.5em;
  line-height: 2rem;
  margin-right: 6rem;
  padding-left: 6rem;
  position: relative;

  @media (max-width: ${vars.breakpoint.tablet}) {
    margin: 0;
    padding: 1rem;
  }

  svg {
    background: #faefd2;
    border-radius: 50%;
    height: 2rem;
    left: 0;
    padding: 0.5rem;
    position: absolute;
    top: 0;
    width: 2rem;

    @media (max-width: ${vars.breakpoint.tablet}) {
      height: 1rem;
      margin-right: 1rem;
      padding: 0.25rem;
      position: relative;
      top: 0.25rem;
      width: 1rem;
    }
  }
`

export const HeadingLineWrapper = styled.h2`
  color: ${(props) => (props.inverse ? '#FFF' : vars.secondary)};
  margin-top: 0;
  padding-top: 4rem;
  position: relative;
  overflow: hidden;
  text-align: center;
`

const HeadingLineContainer = styled.span`
  display: inline-block;
  margin: 0 auto;
  position: relative;

  &::before,
  &::after {
    background-color: ${vars.muted};
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  &::before {
    right: 110%;
  }
  &::after {
    left: 110%;
  }
`

export const HeadingWithLine = ({ children, inverse, bold, ...rest }) => {
  let text = children

  if (typeof children === 'string' && bold)
    text = children.replace(
      /^([\w\-a-zàâçéèêëîïôûùüÿñæœ]+)/,
      '<strong>$1</strong>',
    )

  return (
    <HeadingLineWrapper inverse={inverse} {...rest}>
      <HeadingLineContainer>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </HeadingLineContainer>
    </HeadingLineWrapper>
  )
}
