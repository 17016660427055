import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getOffsetViewport = (prefix = '') => ({ offset }) =>
    offset ? `${prefix}600px` : 0;

const Wrapper = styled.div`
    background: #2a5103;
    margin-top: ${getOffsetViewport('-')};
    padding-top: ${getOffsetViewport()};
    padding-bottom: 5vh;
`;

const SustainabilityWrapper = ({ children, offset }) => (
    <Wrapper offset={offset}>{children}</Wrapper>
);

SustainabilityWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    offset: PropTypes.bool,
};

SustainabilityWrapper.defaultProps = {
    offset: false,
};

export default SustainabilityWrapper;
