import styled from 'styled-components';
import vars from '../lib/styled';

const Container = styled.div`
    margin: 0 auto;
    width: ${vars.container};
`;

export const ContainerSmall = styled(Container)`
    padding: 0 1rem;
    width: 850px;
`;

export default Container;
