import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import styled from 'styled-components'
import Container, { ContainerSmall } from './Container'
import vars from '../lib/styled'
import { ButtonLinkExternal } from './Button'

const GridContainer = styled(ContainerSmall)`
  display: grid;
`

const Aside = styled.section`
  color: #fff;
  text-align: center;
  padding-top: 5vh;
  margin: 0 auto;
`

const PhotoContainer = styled.div`
  border: 1px solid white;
  border-radius: 500px;
  box-shadow: 4px 2px 20px 8px rgba(0, 0, 0, 0.1);
  height: 175px;
  margin: 0 auto 2rem;
  position: relative;
  overflow: hidden;
  width: 175px;

  @media only screen and (min-width: ${vars.breakpoint.laptop}) {
    height: 225px;
    width: 225px;
  }

  & img {
    height: 100%;
    width: 100%;
  }
`

const LargeText = styled.p`
  font-size: 1rem;
  line-height: 1.7;
  font-weight: bold;

  @media only screen and (min-width: ${vars.breakpoint.laptop}) {
    font-size: 1.16rem;
  }
`

const LogoWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 50px;

  @media only screen and (min-width: ${vars.breakpoint.phone}) {
    order: -1;
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
    margin-bottom: 30px;
  }
`

const LogoContainer = styled.div`
  height: 95px;
  width: 350px;
`

const hasBody = (body) =>
  typeof body === 'object' && body !== null && 'childContentfulRichText' in body

const LearnMorePanel = ({ image, body, url, buttonText, subtitle, logos }) => (
  <Container>
    <Aside>
      <GridContainer>
        {hasBody(body) && (
          <div>
            <LargeText>{subtitle}</LargeText>
            <PhotoContainer>
              <Image {...image} />
            </PhotoContainer>
            <LargeText>
              <span
                dangerouslySetInnerHTML={{
                  __html: body.childContentfulRichText.html,
                }}
              />
            </LargeText>
          </div>
        )}
        <ButtonLinkExternal primary href={url}>
          {buttonText}
        </ButtonLinkExternal>
        {Array.isArray(logos) && logos.length ? (
          <LogoWrapper>
            {logos.map((logo) => (
              <LogoContainer key={logo.alt}>
                <Image
                  {...logo}
                  style={{ height: '100%', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </LogoContainer>
            ))}
          </LogoWrapper>
        ) : null}
      </GridContainer>
    </Aside>
  </Container>
)

LearnMorePanel.defaultProps = {}
LearnMorePanel.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
    alt: PropTypes.string,
  }).isRequired,
  body: PropTypes.shape({
    childContentfulRichText: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
  buttonText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  logos: PropTypes.array.isRequired,
}

export default LearnMorePanel
