import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import App from '../components/App'
import Wrapper from '../components/Wrapper'
import Main from '../components/Main'
import ArrowContent, { ArrowContentWrapper } from '../components/ArrowContent'
import HeroVariant from '../components/HeroVariant'
import { HeadingWithLine } from '../components/Heading'
import { HrefLangMeta } from '../components/Locale'
import SEO from '../components/SEO'
import SustainabilityWrapper from '../components/SustainabilityWrapper'
import LearnMorePanel from '../components/LearnMorePanel'
import Packaging from '../components/Packaging'
import TwoColumnBody from '../components/TwoColumnBody'
import { ContainerSmall } from '../components/Container'

const CustomWrapper = styled(Wrapper)`
  padding-bottom: 0;
`

const isUs = (locale) => locale === 'en-US'

const SustainabilityTemplate = ({ data: { translate, page } }) => {
  const {
    image,
    title,
    description,
    callToAction,
    body,
    bodyTitle,
    comparatives,
    node_locale,
    results,
  } = page

  return (
    <App>
      <HrefLangMeta {...translate} />
      <SEO {...{ title, description }} />
      <div style={{ overflow: 'hidden' }}>
        <HeroVariant
          style={{ zIndex: 1, backgroundColor: 'transparent' }}
          description={description}
          image={image}
          title={title}
        />
        <CustomWrapper>
          <SustainabilityWrapper offset>
            <Main marginless>
              <HeadingWithLine bold inverse>
                {bodyTitle}
              </HeadingWithLine>
              <ContainerSmall>
                <TwoColumnBody
                  style={{ paddingBottom: '2rem' }}
                  dangerouslySetInnerHTML={{
                    __html: body.childContentfulRichText.html,
                  }}
                />
              </ContainerSmall>
            </Main>
          </SustainabilityWrapper>
          <ArrowContentWrapper>
            <div style={{ padding: '0 2rem', flex: 1 }}>
              <Packaging columns={comparatives} locale={node_locale} />
            </div>
            <ArrowContent {...results} />
          </ArrowContentWrapper>
          {!isUs(node_locale) && (
            <SustainabilityWrapper>
              <LearnMorePanel {...callToAction} />
            </SustainabilityWrapper>
          )}
        </CustomWrapper>
      </div>
    </App>
  )
}

SustainabilityTemplate.propTypes = {
  data: PropTypes.shape({
    translate: PropTypes.object,
    page: PropTypes.object,
  }).isRequired,
}

export default SustainabilityTemplate

export const query = graphql`
  query contentfulSustainabilityByID(
    $contentful_id: String
    $node_locale: String
  ) {
    translate: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $node_locale }
    ) {
      node_locale
      title
      slug
    }

    page: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      ...PageSEO
      ...CardDetail
      ...PagePhoto
      callToAction {
        buttonText
        url
        image {
          alt: title
          fluid(maxWidth: 450) {
            ...GatsbyContentfulFluid
          }
        }
        body {
          childContentfulRichText {
            html
          }
        }
        subtitle
        logos {
          alt: title
          fluid(maxWidth: 450) {
            ...GatsbyContentfulFluid
          }
        }
      }
      contentful_id
      node_locale
      description {
        description
      }
      body {
        childContentfulRichText {
          html
        }
      }
      results {
        childContentfulRichText {
          html
        }
      }
      bodyTitle
      comparatives {
        node_locale
        title
        inclusions
        exclusions
        figures {
          title
          description
          fluid(maxWidth: 450) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
