import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Container from './Container'
import Grid, { Third } from './Grid'
import { Check, Uncheck } from './CheckboxIcon'
import { HeadingWithLine } from './Heading'
import { useLang } from './Locale'

const getArray = (a) => (Array.isArray(a) ? a : [])

//= ===============================================================================
// CSS helpers
//= ===============================================================================

const Column = styled.div`
  background: ${({ muted }) => (muted ? 'rgb(239,238,238)' : '#FFF')};
  padding: 2rem;
`

const ColumnTitle = styled.h3`
  text-align: center;
`

const Wrapper = styled.section`
  background-color: #fff;
  padding: 5vh 0;
`

const ListItemIcon = styled.span`
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`

const ListItem = styled.li`
  align-items: center;
  display: flex;
  margin: ${({ isLast }) => (isLast ? '1rem 0 2rem' : '1rem 0')};
  font-size: 1rem;
`

const List = styled.ul`
  list-style: none;
  padding: 0.1rem 2rem 0;
  margin: 0;
`

const Figcaption = styled.figcaption`
  font-style: italic;
  font-size: 0.833rem;
  text-align: center;
`

const Figure = styled.figure`
  margin: 0 auto 1rem auto;

  .gatsby-image-wrapper {
    max-height: 195px;
  }
`

//= ===============================================================================
// Partials
//= ===============================================================================

const PackaginColumn = ({ title, children, muted }) => (
  <Third>
    <Column muted={muted}>
      <ColumnTitle>{title}</ColumnTitle>
      {children}
    </Column>
  </Third>
)

const ListItemText = ({ check, children, isLast }) => {
  return (
    <ListItem isLast={isLast}>
      <ListItemIcon aria-hidden={true}>
        {check ? <Check /> : <Uncheck />}
      </ListItemIcon>
      <strong>{children}</strong>
    </ListItem>
  )
}

const FigureImage = ({ children, caption }) => (
  <Figure>
    {children}
    <Figcaption>{caption}</Figcaption>
  </Figure>
)

const ListItemTitle = ({ children }) => (
  <li>
    <h4
      style={{
        margin: 0,
        fontSize: '1.255rem',
      }}
    >
      {children}
    </h4>
  </li>
)

const ListGroup = ({ data, children, ...rest }) =>
  getArray(data).map((item, i) => (
    <React.Fragment key={item}>
      {i === 0 && <ListItemTitle>{children}</ListItemTitle>}
      <ListItemText {...rest} isLast={i === data.length - 1}>
        {item}
      </ListItemText>
    </React.Fragment>
  ))

//= ===============================================================================
// Components
//= ===============================================================================

const Packaging = ({ columns, locale }) => {
  const t = useLang()

  return (
    <Wrapper>
      <HeadingWithLine
        bold
        style={{ marginBottom: '3rem', paddingTop: '2rem' }}
      >
        {t('packaging')}
      </HeadingWithLine>
      <Grid>
        {columns.map(({ title, inclusions, exclusions, figures }, i) => (
          <PackaginColumn key={title} title={title} muted={i % 2}>
            {figures
              ? figures.map((figure) => (
                  <FigureImage
                    key={figure.description}
                    caption={figure.description}
                  >
                    <Image
                      fluid={figure.fluid}
                      alt={figure.title}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </FigureImage>
                ))
              : null}
            <List>
              <ListGroup data={exclusions}>{t('excludes')}</ListGroup>
              <ListGroup check data={inclusions}>
                {t('includes')}
              </ListGroup>
            </List>
          </PackaginColumn>
        ))}
      </Grid>
    </Wrapper>
  )
}

export default Packaging
