import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import styled from 'styled-components'
import vars from '../lib/styled'
import { Header, HeaderHeadline, HeaderLeader } from './Hero'
import showdown from 'showdown'
import BreadCrumbs from './Breadcrumbs'
import { ButtonLink } from './Button'
import MarcangeloCaresLogo from './MarcangeloCaresLogo'

const HeaderWrapper = styled.div`
  background-color: ${vars.muted};
  position: relative;

  .gatsby-image-wrapper {
    height: 28vw;
    min-height: 350px;
    max-height: 550px;
    left: 0;
    top: 0;
    width: 100%;
  }
`

export const HeaderGrid = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`

export const HeaderHalf = styled.div`
  padding: 6vh 2vw 6vh 12vw;
  width: 50%;

  @media (max-width: ${vars.breakpoint.tablet}) {
    padding: 8vh 6vw;
    width: 100%;
  }
`

export const HeaderHalfImage = styled.div`
  width: 50%;
  position: relative;
  height: 500px;

  @media (max-width: ${vars.breakpoint.tablet}) {
    width: 100%;
  }

  .gatsby-image-wrapper {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute !important;
    top: 0;
  }
`

export const HeaderContent = styled.div`
  background-color: ${vars.contrast};
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: inline-block;
  padding: 2.5rem 6rem 2.5rem 6rem;
  margin-top: -10rem;
  position: relative;
  z-index: 3;
  max-width: 80%;

  @media (max-width: ${vars.breakpoint.tablet}) {
    padding: 2rem 1rem;
    width: 100%;
    max-width: 100%;
  }

  h1 {
    margin-top: 0;
  }
`

const HeaderFill = styled.div`
  background: inherit;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 100%;
  width: 2000px;
`

const HeroVariant = ({
  title,
  image,
  description,
  buttonDestination,
  button,
  ...rest
}) => (
  <HeaderWrapper {...rest}>
    {image && (
      <div aria-hidden={true}>
        <Image fluid={image.fluid} alt="" />
      </div>
    )}
    <Header style={{ paddingTop: 0, paddingBottom: 0 }}>
      <HeaderContent>
        <HeaderFill aria-hidden={true} />
        <MarcangeloCaresLogo />
        <HeaderHeadline>{title}</HeaderHeadline>
        <hr aria-hidden="true" className="divider" />
        {!description ? (
          <BreadCrumbs title={title} />
        ) : (
          <div>
            {description && (
              <HeaderLeader>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new showdown.Converter().makeHtml(
                      description.description,
                    ),
                  }}
                />
              </HeaderLeader>
            )}
            {buttonDestination && (
              <ButtonLink
                primary
                to={buttonDestination}
                aria-label="Click to view featured products"
              >
                {button}
              </ButtonLink>
            )}
          </div>
        )}
      </HeaderContent>
    </Header>
  </HeaderWrapper>
)

HeroVariant.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  buttonDestination: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.object,
    title: PropTypes.string,
  }).isRequired,
}

export default HeroVariant
