import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.div`
    column-count: 1; 
    color: #FFF;
    margin: 2rem auto 0;

    @media only screen and (min-width: ${vars.breakpoint.laptop}) {
      column-count: 2; 
    }

    & p:first-of-type {
      margin-top: 0
    }
`;
