import React from 'react'
import { useLocation } from '@reach/router'
import sustainabilityLogoSrc from '../../static/caresLogo.jpg'

const MarcangeloCaresLogo = () => {
  const { pathname } = useLocation()
  return pathname.includes('sustainability') ||
    pathname.includes('durabilite') ? (
    <img
      style={{
        mixBlendMode: 'multiply',
        display: 'block',
        marginBottom: '1rem',
        maxWidth: '100%',
        width: 295,
      }}
      src={sustainabilityLogoSrc}
      alt="Marcangelo Cares"
    />
  ) : null
}

export default MarcangeloCaresLogo
