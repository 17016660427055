import React from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import vars from '../lib/styled'

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: 450px solid transparent;
  border-bottom: 450px solid transparent;
  border-right: 4rem solid rgb(201 219 49);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  @media (max-width: ${vars.breakpoint.laptop}) {
    border-bottom: 4rem solid rgb(201 219 49);
    border-left: 450px solid transparent;
    border-right: 450px solid transparent;
    border-top: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Box = styled.div`
  background-color: rgb(201 219 49);
  color: rgb(42 81 3);
  padding: 2rem 2rem 2rem 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;

  p {
    font-size: 1.16rem;
  }

  @media (max-width: ${vars.breakpoint.laptop}) {
    border-radius: 0;
    padding: 1rem 3rem;
    text-align: center;
  }
`

const Hidden = styled.div`
  position: relative;
  padding-left: 4rem;
  overflow: hidden;

  @media (max-width: ${vars.breakpoint.laptop}) {
    padding-left: 0;
    padding-top: 4rem;
  }
`

const Container = styled.div`
  min-width: 410;
  width: 22.5vw;

  @media (max-width: ${vars.breakpoint.laptop}) {
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: ${vars.breakpoint.phone}) {
    padding: 0;
    min-width: none;
    width: 100%;
  }
`

export const ArrowContentWrapper = styled.div`
  align-items: center;
  display: flex;
  background-color: #fff;
  flexwrap: nowrap;
  width: 100%;
  padding: 2rem;

  @media (max-width: ${vars.breakpoint.laptop}) {
    display: block;
    padding: 0 0 2rem;
  }

  @media (max-width: ${vars.breakpoint.phone}) {
    padding: 0;
  }
`

const ArrowContent = (props) => {
  const __html = get(props, 'childContentfulRichText.html')

  if (!__html) return null

  return (
    <Container>
      <Hidden>
        <Arrow />
        <Box
          dangerouslySetInnerHTML={{
            __html,
          }}
        />
      </Hidden>
    </Container>
  )
}

export default ArrowContent
